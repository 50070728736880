body {
  text-transform: uppercase;
  min-height: -webkit-fill-available;
  margin: 0 auto;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Countdown {
  display: flex;
  justify-content: space-around;
  font-family: sans-serif;
  height: 100%;
  align-items: center;
  margin: 0 0 40px;
}

.time {
  font-size: 32px;
}

@media only screen and (max-device-width: 768px) {
  .Countdown {
    flex-direction: column;
  }

  .time {
    font-size: 22px;
  }
}

.tile-grid-container {
  display: flex;
  flex-wrap: wrap;
  height: 95%;
  align-items: center;
  justify-content: space-evenly;
}

.tile-grid {
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.tile {
  width: 100px;
  height: 100px;
  background: linear-gradient(45deg, #2196f3 30%, #21cbf3 90%);
  border: 0;
  padding: 0 30px;
  box-shadow: 0 3px 5px 2px rgba(33, 203, 243, 0.3);
  color: white;
  justify-content: center;
}

.tile-card {
  display: flex;
  justify-content: center;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.tile-text {
  font-size: 55px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

@media only screen and (max-device-width: 500px) {
  .tile {
    width: 86px;
    height: 86px;
    padding: 0;
  }
  .tile-grid {
    margin: 0 0 10px;
  }
  .MuiButtonBase-root.MuiButton-root.MuiButton-text.tile-text {
    font-size: 40px;
  }
}
